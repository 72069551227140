
export default {
    data() {
        return {
            // elmentList:
            // [
            //     {
            //         pcElementId: 114,
            //         phoneElementId: 125,
            //         descList: [
            //             {
            //                 descName: 'Track-hanging Inspection Robot for Power Industry',
            //                 sort: 1
            //             }, {
            //                 descName: 'IP55',
            //                 sort: 2
            //             }, {
            //                 descName: '1m/s',
            //                 sort: 3
            //             }, {
            //                 descName: '<30kg',
            //                 sort: 4
            //             }, {
            //                 descName: '≤25°',
            //                 sort: 5
            //             },
            //             {
            //                 descName: 'Protection Level',
            //                 sort: 6
            //             }, {
            //                 descName: 'Running Speed',
            //                 sort: 7
            //             }, {
            //                 descName: 'Curb Weight',
            //                 sort: 8
            //             }, {
            //                 descName: 'Gradeability',
            //                 sort: 9
            //             }],
            //          type: 'image',
            //         sort: 1
            //     },
            //     {
            //         pcElementId: 115,
            //         phoneElementId: 126,
            //         descList: [
            //             {
            //                 descName: 'Track-hanging Inspection Robot for Power Industry',
            //                 sort: 1
            //             }, {
            //                 descName: 'Provide functions such as meter identification reading, equipment infrared temperature measurement, environmental gas monitoring, partial discharge monitoring, etc., which can be used in application scenarios such as substation areas to conduct inspections for equipment appearance defects, meter readings, switch positions, equipment temperature measurement, sound collection, etc. The track system is designed and installed according to the actual situation of the project site, and the robot walks along the track to achieve comprehensive coverage of the objects to be inspected on the project site, which can fully utilize the site space, improve safety and space utilization.',
            //                 sort: 2
            //             },],
            //          type: 'image',
            //         sort: 2
            //     },
            //     {
            //         pcElementId: 116,
            //         phoneElementId: 127,
            //         descList: [
            //             {
            //                 descName: 'Application Scenarios',
            //                 sort: 1
            //             }, {
            //                 descName: 'Track-hanging Inspection Robot for Power Industry is suitable for intelligent inspection business scenarios at stations such as substations, converter stations, distribution rooms and switching stations',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 3
            //     },
            //     {
            //         pcElementId: 117,
            //         phoneElementId: 128,
            //         descList: [
            //             {
            //                 descName: 'Unmanned Autonomous Inspection',
            //                 sort: 1
            //             }, {
            //                 descName: 'No manual intervention is required, automatic inspection, manual inspection, abnormal inspection and automatic inspection along the track can be realized.',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 4
            //     },
            //     {
            //         pcElementId: 118,
            //         phoneElementId: 129,
            //         descList: [
            //             {
            //                 descName: 'Intelligent Obstacle Avoidance Function',
            //                 sort: 1
            //             }, {
            //                 descName: 'Equipped with obstacle avoidance sensors, safe operation, obstacle avoidance and early warning',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 5
            //     },
            //     {
            //         pcElementId: 119,
            //         phoneElementId: 130,
            //         descList: [
            //             {
            //                 descName: 'Infrared Temperature Measurement of Equipment',
            //                 sort: 1
            //             }, {
            //                 descName: 'Equipped with infrared thermal imager, it can collect the surface temperature of the device and give early warning for abnormal temperature.',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 6
            //     },
            //     {
            //         pcElementId: 120,
            //         phoneElementId: 131,
            //         descList: [
            //             {
            //                 descName: 'Meter Recognition Reading',
            //                 sort: 1
            //             }, {
            //                 descName: 'By using AI visual technology to read data from various instruments and meters, if the collected data exceeds the threshold, an alert will be generated.By using AI visual identification technology and other means for leakage detection, the coordinates of the leakage location can be displayed and an early warning can be given.',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 7
            //     },
            //     {
            //         pcElementId: 121,
            //         phoneElementId: 132,
            //         descList: [
            //             {
            //                 descName: 'Video Storage Monitoring',
            //                 sort: 1
            //             }, {
            //                 descName: 'Equipped with high-definition camera and video management equipment, it has live broadcasting and video recording functions, and can be centrally stored and replayed.',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 8
            //     },
            //     {
            //         pcElementId: 122,
            //         phoneElementId: 133,
            //         descList: [
            //             {
            //                 descName: 'Partial Discharge Monitoring',
            //                 sort: 1
            //             }, {
            //                 descName: 'Partial discharge monitoring for electrical equipment can effectively evaluate the degree of insulation deterioration inside the equipment.',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 9
            //     },
            //     {
            //         pcElementId: 123,
            //         phoneElementId: 134,
            //         descList: [
            //             {
            //                 descName: 'Track-hanging Inspection Robot for Power Industry',
            //                 sort: 0
            //             }, {
            //                 descName: 'Specification',
            //                 sort: 1
            //             },{
            //                 descName: 'Dimension (L×W×H)',
            //                 sort: 2
            //             },
            //             {
            //                 descName: 'Protection Level',
            //                 sort: 3
            //             },
            //             {
            //                 descName: 'Walking Speed',
            //                 sort: 4
            //             },
            //             {
            //                 descName: 'Turning Radius',
            //                 sort: 5
            //             },
            //             {
            //                 descName: 'Positioning Mode',
            //                 sort: 6
            //             },
            //             {
            //                 descName: 'Ambient Temperature',
            //                 sort: 7
            //             },
            //             {
            //                 descName: 'Curb Weight',
            //                 sort: 8
            //             },
            //             {
            //                 descName: 'Waling Mode',
            //                 sort: 9
            //             }, {
            //                 descName: 'Gradeability',
            //                 sort: 10
            //             },
            //             {
            //                 descName: 'Walking Safety',
            //                 sort: 11
            //             },
            //             {
            //                 descName: 'Repetitive Navigation Positioning Accuracy',
            //                 sort: 12
            //             },
            //             {
            //                 descName: 'Ambient Relative Humidity',
            //                 sort: 13
            //             }, {
            //                 descName: '≤450mm×330mm×760mm',
            //                 sort: 14
            //             },
            //             {
            //                 descName: 'IP55',
            //                 sort: 15
            //             },
            //             {
            //                 descName: '0-1m/s,Speed Adjustable',
            //                 sort: 16
            //             },
            //             {
            //                 descName: '450mm',
            //                 sort: 17
            //             },
            //             {
            //                 descName: 'Odometer + RFID Calibration',
            //                 sort: 18
            //             },
            //             {
            //                 descName: '-25°C-+60C',
            //                 sort: 19
            //             },
            //             {
            //                 descName: '<30kg',
            //                 sort: 20
            //             },
            //             {
            //                 descName: 'Track Walking (sliding contact line)',
            //                 sort: 21
            //             }, {
            //                 descName: '≤25°',
            //                 sort: 22
            //             },
            //             {
            //                 descName: 'Equipped with front, rear and bottom three-direction ultrasonic radar',
            //                 sort: 23
            //             },
            //             {
            //                 descName: '≤3mm',
            //                 sort: 24
            //             },
            //             {
            //                 descName: '5%-95%',
            //                 sort: 25
            //             }],
            //          type: 'image',
            //         sort: 10
            //     },
            //     {
            //         pcElementId: 124,
            //         phoneElementId:135,
            //         descList: [
            //             {
            //                 descName: 'Track-hanging Inspection Robot for Power Industry',
            //                 sort: 1
            //             }, {
            //                 descName: 'Make full use of site space to enhance safety',
            //                 sort: 2
            //             }],
            //          type: 'image',
            //         sort: 11
            //     }
            // ],
            elmentList:[],
        }
    },
    created() {
        //              this.$store.dispatch("pageset/getbannerIndex", 0);
        //         this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
             // 获取当前页面的配置信息
             this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
                this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
            this.$router.push('/pdfView')
        },

    }
}